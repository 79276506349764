<template>
  <!--begin::Card-->
  <div>
    <div class="form-group">
      <label>Day of week</label>
      <b-form-select v-model="day" :options="days"></b-form-select>
      <span class="form-text text-muted">Select a day of the week.</span>
    </div>
    <div class="form-group">
      <label>Opening Time</label>
      <b-form-timepicker v-model="open_time" v-on:blur="checkNextDay" locale="en"></b-form-timepicker>
      <span class="form-text text-muted"
        >Choose the opening time for this slot.</span
      >
    </div>
    <div class="form-group">
      <label>Closing Time</label>
      <b-form-timepicker v-model="close_time" v-on:blur="checkNextDay" locale="en"></b-form-timepicker>
      <span class="form-text text-muted"
        >Choose the closing time for this slot.</span
      >
    </div>
    <div class="form-group" v-if="next">
      
      <label>Next Day Closing ? </label>
      &nbsp;&nbsp;

      <input
          v-model="next_day"
          type="checkbox"
      />
      <span class="form-text text-muted"
        >A new record will be entered for the next day if marked</span
      >
    </div>
    <div class="form-group">
      <label>Delivery Available</label>
      <b-form-radio v-model="delivery_available" value="1" selected>
        Yes
      </b-form-radio>
      <b-form-radio v-model="delivery_available" value="0"> No </b-form-radio>
      <span class="form-text text-muted">
        Is delivery available during this timeslot.
      </span>
    </div>
    <!-- <div class="form-group">
      <label>Average Delivery Time</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="avg_delivery_time"
        placeholder="Average Delivery Time"
      />
      <span class="form-text text-muted"> Average Delivery time </span>
    </div> -->
    <div class="form-group">
      <label>Collection Available</label>
      <b-form-radio v-model="collection_available" value="1" selected>
        Yes
      </b-form-radio>
      <b-form-radio v-model="collection_available" value="0"> No </b-form-radio>
      <span class="form-text text-muted">
        Is collection available during this timeslot.
      </span>
    </div>
    <!-- <div class="form-group">
      <label>Average Collection Time</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="avg_collection_time"
        placeholder="Average Collection Time"
      />
      <span class="form-text text-muted"> Average Collection time </span>
    </div> -->
    <div>
      <label>Accept order before closing</label>
      <input
        type="number"
        min="0"
        style="border: 1px solid black"
        class="col-sm-8 form-control-solid form-control-lg"
        v-model="accept_before_closing"
        placeholder="Accept order before"
      />
      <b-form-select
        class="col-sm-4"
        v-model="timing"
        :options="timings"
      ></b-form-select>
      <span class="form-text text-muted">
        Set how many min.before closing time a customer can make an order. Eg.
        Monday 5pm - 7PM . Set this value to 10, then Customer can place order
        until 6:50 PM.
      </span>
    </div>
    <hr />
    <div class="form-group" style="float: right">
      <b-button @click="cancel">Cancel</b-button> &nbsp;&nbsp;
      <b-button @click="save">Save</b-button>
    </div>
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";
import { TIMINGS, GET_TIMINGS } from "@/core/services/store/config.module";

export default {
  name: "OpeningTimeForm",
  props: ["record"],
  data() {
    return {
      timing: "",
      days: [
        { value: "Monday", text: "Monday" },
        { value: "Tuesday", text: "Tuesday" },
        { value: "Wednesday", text: "Wednesday" },
        { value: "Thursday", text: "Thursday" },
        { value: "Friday", text: "Friday" },
        { value: "Saturday", text: "Saturday" },
        { value: "Sunday", text: "Sunday" },
      ],
      timings: ["hour", "minute"],
      day: null,
      next : false,
      next_day : false,
      open_time: "",
      close_time: "",
      collection_available: "1",
      avg_delivery_time: "",
      delivery_available: "1",
      avg_collection_time: "",
      accept_before_closing: "",
      business_timing_id: null,
    };
  },
  watch: {
    // whenever question changes, this function will run
    open_time() {
      this.checkNextDay();
    },
    close_time() {
      this.checkNextDay();
    }
  },
  methods: {
    checkNextDay() {
      this.next = false;
      if (this.open_time && this.close_time) {
        if (this.close_time < this.open_time) {
          this.next = true;
        }
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    save() {
      if (!this.day) {
        Swal.fire("Validation!", "Day must be selected", "warning");
        return;
      }
      
      if (!this.open_time) {
        Swal.fire("Validation!", "Opening timings must be entered", "warning");
        return;
      }
      if (!this.close_time) {
        Swal.fire("Validation!", "Closing timings must be entered", "warning");
        return;
      }
      if (!this.timing) {
        Swal.fire("Validation!", "Please select the timing type to accept order before closing", "warning");
        return;
      }
      else if ((this.close_time <= this.open_time) && (! this.next_day)) {
        Swal.fire("Validation!", "Invalid Open and Close time", "error");
        return;
      }

      this.$store
        .dispatch(TIMINGS, {
          day: this.day,
          next_day : this.next_day,
          open_time: this.open_time,
          close_time: this.close_time,
          collection_available: this.collection_available,
          avg_delivery_time: this.avg_delivery_time,
          delivery_available: this.delivery_available,
          avg_collection_time: this.avg_collection_time,
          accept_before_closing: this.accept_before_closing,
          timing: this.timing,
          business_timing_id: this.business_timing_id,
        })
        // go to which page after successfully login
        .then((resp) => {
          // this.form.orders = resp.data;
          if (resp.status === "Success") {
            Swal.fire("Information!", resp.message, "success");
            this.$emit("update");
            return;
          } else {
            Swal.fire("Error", resp.message, "error");
            return;
          }
          // this.$router.push({ name: "dashboard" })
        })
        .catch((error) => {
          if (error.status === "Error") {
            Swal.fire("Error", error.message, "error");
            return;
          }
          console.log(error);
        });
    },
  },

  mounted() {
    if (this.record) {
      this.day = this.record.day;
      this.open_time = this.record.open_time;
      this.close_time = this.record.close_time;
      this.collection_available = this.record.collection_available;
      this.delivery_available = this.record.delivery_available;
      this.avg_delivery_time = this.record.avg_delivery_time;
      this.avg_collection_time = this.record.avg_collection_time;
      this.accept_before_closing = this.record.accept_before_closing;
      this.timing = this.record.timing;
      this.business_timing_id = this.record.business_timing_id;
    }
  },
};
</script>
